import type {
	BusinessType,
	INotification,
} from "@metronome/types/Notifications";
import type { ICursorPaginatedResults } from "@metronome/types/PaginatedResponse";
import {
	type UseMutationResult,
	useInfiniteQuery,
	useMutation,
	useQueryClient,
} from "@tanstack/react-query";

import { apiGet, apiPut } from "./api";

type NotificationResponse = INotification[] | { data: undefined } | undefined;

function updateNotifications(
	notificationId: string,
	isRead: boolean,
): Promise<NotificationResponse> {
	return apiPut<INotification[]>(
		`/notifications/mark/${isRead ? "unread" : "read"}`,
		{
			notificationIds: [notificationId],
		},
	).then((response) => response.data);
}

type ToggleNotifPayload = {
	notificationId: string;
	isRead: boolean;
};
export function useToggleReadNotificationMutation(): UseMutationResult<
	NotificationResponse,
	Error,
	ToggleNotifPayload,
	{ previousNotif: INotification[] | undefined }
> {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: ({ notificationId, isRead }) =>
			updateNotifications(notificationId, isRead),

		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["notifications"],
			});
		},
	});
}

export function useInfiniteNotifications(type: BusinessType) {
	return useInfiniteQuery({
		queryKey: ["notifications", type],
		queryFn: async ({ pageParam }) =>
			apiGet<ICursorPaginatedResults<INotification> | undefined>(
				"/notifications",
				{
					params: { cursor: pageParam, type },
				},
			),
		initialPageParam: undefined,
		getNextPageParam: (lastPage) => lastPage?.nextCursor,
	});
}

function markAllNotificationsAsRead(): Promise<NotificationResponse> {
	return apiPut<INotification[]>("/notifications/mark/read", {}).then(
		(response) => response.data,
	);
}

export function useReadAllNotificationMutation(): UseMutationResult<
	INotification[] | { data: undefined } | undefined,
	Error,
	unknown,
	{ previousNotif: INotification[] | undefined }
> {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: markAllNotificationsAsRead,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["notifications"],
			});
		},
	});
}
