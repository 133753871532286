import React, { useCallback } from "react";

import { useUpsertMetadataValue } from "@metronome/api/useMetadataDefinitions";
import { EditableCell } from "@metronome/components/EditableCell";
import { HighlightWords } from "@metronome/components/HighlightWords";
import type {
	IBusinessDimensionNode,
	IMetadataValue,
} from "@metronome/types/BusinessDimension";
import type { Context } from "@metronome/types/Context";
import type { IMetadataDefinition } from "@metronome/types/MetadataDefinition";
import { getInputType } from "@metronome/utils/metadataType";
import { ComboBoxMultiSelect } from "@metronome/components/ComboBoxMultiSelect";

type EditSingleMetadataProps = {
	context: Context;
	contextId: string;
	node: IBusinessDimensionNode;
	metadataDefinition: IMetadataDefinition;
	query?: string;
};

type MultiSelectMetadataProps = {
	metadataDefinition: IMetadataDefinition;
	metaData?: IMetadataValue;
	businessDimension?: IBusinessDimensionNode;
	mutateNodeName: ReturnType<typeof useUpsertMetadataValue>["mutate"];
};

export const MultiSelectMetadata: React.FC<MultiSelectMetadataProps> = ({
	metadataDefinition,
	metaData,
	businessDimension,
	mutateNodeName,
}) => {
	const [value, setValue] = React.useState<string[]>(
		Array.isArray(metaData?.value) ? metaData?.value : [metaData?.value],
	);

	const [isDirty, setIsDirty] = React.useState(false);

	const onMenuToggle = useCallback(() => {
		if (isDirty && businessDimension?.id) {
			mutateNodeName({
				definitionId: metadataDefinition.id,
				nodeId: businessDimension?.id,
				metadataValues: [
					metaData?.id
						? {
								id: metaData.id,
								value,
							}
						: {
								value,
							},
				],
			});
		}
	}, [
		businessDimension?.id,
		isDirty,
		metaData?.id,
		metadataDefinition.id,
		mutateNodeName,
		value,
	]);

	if (metadataDefinition.enum)
		return (
			<>
				<ComboBoxMultiSelect
					items={metadataDefinition.enum.map((p) => ({
						label: p,
						value: p,
					}))}
					value={value}
					setValue={(value: string[]) => {
						setIsDirty(true);
						setValue(value);
					}}
					onClose={onMenuToggle}
				/>
			</>
		);

	return null;
};

export const EditSingleMetadata: React.FC<EditSingleMetadataProps> = ({
	context,
	contextId,
	metadataDefinition,
	node,
	query,
}) => {
	const { mutate: mutateNodeName } = useUpsertMetadataValue(contextId, context);

	const getMetaData = node?.metadataValues.find(
		(metaData) => metaData.definition.id === metadataDefinition.id,
	);
	if (metadataDefinition.restrictedNodeTypes.length) {
		const nodeTypesMatch = metadataDefinition.restrictedNodeTypes.find(
			(rNodeTypes) => rNodeTypes?.id === node.nodeType?.id,
		);

		if (getMetaData?.value && !nodeTypesMatch) {
			return (
				<HighlightWords
					content={
						Array.isArray(getMetaData.value)
							? getMetaData.value.join()
							: getMetaData.value
					}
					query={query ?? ""}
				/>
			);
		}
	}

	if (metadataDefinition.type === "singleSelect") {
		return (
			<select
				className="w-full"
				value={getMetaData?.value}
				onChange={(e) => {
					if (node?.id && getMetaData?.id) {
						mutateNodeName({
							definitionId: getMetaData.definition.id,
							nodeId: node.id,
							metadataValues: [
								{
									id: getMetaData.id,
									value: e.target.value,
								},
							],
						});
					}
				}}
			>
				<option key="empty" />
				{metadataDefinition.enum?.map((v) => (
					<option key={v} value={v}>
						{v}
					</option>
				))}
			</select>
		);
	}

	if (metadataDefinition.type === "multiSelect") {
		if (
			metadataDefinition.enum &&
			getMetaData &&
			Array.isArray(getMetaData?.value)
		) {
			return (
				<MultiSelectMetadata
					metaData={getMetaData}
					businessDimension={node}
					metadataDefinition={metadataDefinition}
					mutateNodeName={mutateNodeName}
				/>
			);
		}
	}

	if (typeof getMetaData?.value === "string") {
		return (
			<EditableCell
				value={getMetaData?.value}
				type={getInputType(metadataDefinition.type)}
				onValidate={(v: string) => {
					if (node?.id && getMetaData?.id) {
						mutateNodeName({
							definitionId: getMetaData.definition.id,
							nodeId: node.id,
							metadataValues: [{ id: getMetaData.id, value: v }],
						});
					}
				}}
			/>
		);
	}
	return <span>fail to parse data</span>;
};
