import type { ResourceAllocation } from "@metronome/types/Resources";
import {
	Select,
	SelectTrigger,
	SelectValue,
	SelectContent,
	SelectItem,
} from "@metronome/components/ui/select";
import { useNavigate } from "@tanstack/react-router";
import { useCallback } from "react";

export const SelectResource: React.FC<{
	resources: ResourceAllocation;
	nodeReferenceId: string;
}> = ({ resources, nodeReferenceId }) => {
	const navigate = useNavigate();

	const updateResourceParams = useCallback(
		(nodeReferenceId: string) => {
			navigate({
				search: {
					nodeReferenceId,
					stepTemplateId: resources.find(
						(r) => r.nodeReferenceId === nodeReferenceId,
					).stepTemplateId,
				},
			});
		},
		[navigate, resources],
	);

	return (
		<Select
			onValueChange={updateResourceParams}
			value={nodeReferenceId ?? resources[0].nodeReferenceId}
		>
			<SelectTrigger className="w-72">
				<SelectValue />
			</SelectTrigger>
			<SelectContent>
				{resources.map((r) => (
					<SelectItem key={r.nodeReferenceId} value={r.nodeReferenceId}>
						{r.nodeReferenceLabel}
					</SelectItem>
				))}
			</SelectContent>
		</Select>
	);
};
