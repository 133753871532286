import { SchedulerError } from "@metronome/components/SchedulerError";
import { Epg, Layout, useEpg } from "@nessprim/planby-pro";
import {
	Select,
	SelectTrigger,
	SelectValue,
	SelectContent,
	SelectItem,
} from "@metronome/components/ui/select";
import { Slider } from "@metronome/components/ui/slider";
import { FormattedMessage } from "react-intl";
import { CustomItem } from "./planby/CustomItem";
import { CustomTimeline } from "./planby/CustomTimeline";
import type {
	Area,
	ChannelWithOmittedUuid,
	Mode,
	ProgramWithOmittedUuid,
} from "@nessprim/planby-pro/dist/Epg/helpers";
import {
	durationInDays,
	getAvailableRange,
	getDayWidth,
	getDefaultTimeRange,
	getStartDate,
	globalStyles,
} from "@metronome/utils/planby";
import { useState } from "react";
import {
	EXPANDED_SIZE,
	COMPACT_SIZE,
	planbyLightTheme,
} from "@metronome/constants/planbyTheme";
import { endOfDay } from "date-fns/endOfDay";
import { format } from "date-fns/format";
import SwitchButton from "@metronome/components/Switch";
import { ErrorBoundary } from "@sentry/react";

const SelectRange = ({ rangeMode, setRangeMode, availableRanges }) => {
	return (
		<Select
			value={rangeMode}
			onValueChange={(value: Mode["type"]) => setRangeMode(value)}
		>
			<SelectTrigger className="w-fit">
				<SelectValue placeholder="Select range" />
			</SelectTrigger>
			<SelectContent>
				{availableRanges.map((range) => (
					<SelectItem key={range} value={range}>
						<FormattedMessage id={range.toUpperCase()} />
					</SelectItem>
				))}
			</SelectContent>
		</Select>
	);
};

type SelectTimelineRangeProps = {
	setTimelineRangeInHours: (val: number) => void;
	timelineRangeInHours: number;
};
const SelectTimelineRange: React.FC<SelectTimelineRangeProps> = ({
	setTimelineRangeInHours,
	timelineRangeInHours,
}) => {
	return (
		<Select
			onValueChange={(val: string) => setTimelineRangeInHours(Number(val))}
			value={String(timelineRangeInHours)}
		>
			<SelectTrigger className="w-fit">
				<SelectValue placeholder="Select time range" />
			</SelectTrigger>
			<SelectContent>
				<SelectItem value="0">
					<FormattedMessage id="EVERY_HOUR" />
				</SelectItem>
				<SelectItem value="2">
					<FormattedMessage id="EVERY_TWO_HOURS" />
				</SelectItem>
				<SelectItem value="4">
					<FormattedMessage id="EVERY_FOUR_HOURS" />
				</SelectItem>
				<SelectItem value="6">
					<FormattedMessage id="EVERY_SIX_HOURS" />
				</SelectItem>
			</SelectContent>
		</Select>
	);
};

type SelectDisplayModeProps = {
	displayMode: "compact" | "expanded";
	setDisplayMode: (display: "compact" | "expanded") => void;
};
const SelectDisplayMode: React.FC<SelectDisplayModeProps> = ({
	displayMode,
	setDisplayMode,
}) => {
	return (
		<Select
			value={displayMode}
			onValueChange={(value: "compact" | "expanded") => setDisplayMode(value)}
		>
			<SelectTrigger className="w-fit">
				<SelectValue placeholder="Select mode" />
			</SelectTrigger>
			<SelectContent>
				<SelectItem value="compact">
					<FormattedMessage id="COMPACT" />
				</SelectItem>
				<SelectItem value="expanded">
					<FormattedMessage id="EXPANDED" />
				</SelectItem>
			</SelectContent>
		</Select>
	);
};

type StepsTimelineProps = {
	plannedStart: string;
	plannedEnd: string;
	epg: ProgramWithOmittedUuid[];
	channels: ChannelWithOmittedUuid[];
	areas?: Area[];
	CustomChannelItem?: React.ComponentType<{
		channel: ChannelWithOmittedUuid;
	}>;
	selectedMetadataDefs?: string[];
};
export const StepsTimeline: React.FC<StepsTimelineProps> = ({
	plannedStart,
	plannedEnd,
	epg,
	areas,
	channels = [],
	CustomChannelItem,
	selectedMetadataDefs,
}) => {
	// getters
	const defaultRange = getDefaultTimeRange(plannedStart, plannedEnd);
	const availableRanges = getAvailableRange(defaultRange);
	const numberOfDays = durationInDays(plannedStart, plannedEnd);
	const startDate = getStartDate(plannedStart);

	// state
	const [rangeMode, setRangeMode] = useState<Mode["type"]>(defaultRange);
	const [timelineRangeInHours, setTimelineRangeInHours] = useState(0);
	const [displayMode, setDisplayMode] = useState<"compact" | "expanded">(
		"expanded",
	);
	const [dayWidthMultiplier, setDayWidthMultiplier] = useState([1]);
	const [showSchedule, setShowSchedule] = useState(false);

	const endDate = rangeMode === "day" ? plannedEnd : endOfDay(plannedEnd);
	const endDateFormatted = format(endDate, "yyyy-MM-dd'T'HH:mm:ss");

	// planBy hooks
	const { getEpgProps, getLayoutProps } = useEpg({
		epg,
		areas,
		channels,
		startDate,
		// width: 500,
		endDate: endDateFormatted,
		mode: { type: rangeMode, style: "modern" },
		sidebarWidth: 180,
		dayWidth: getDayWidth(rangeMode, numberOfDays) * dayWidthMultiplier[0],
		itemHeight: displayMode === "expanded" ? EXPANDED_SIZE : COMPACT_SIZE,
		isSidebar: !!CustomChannelItem,
		isTimeline: true,
		isLine: true,
		isCurrentTime: true,
		theme: planbyLightTheme,
		overlap: {
			enabled: true,
			mode: "stack",
		},
		globalStyles,
	});

	if (!(new Date(plannedStart) <= new Date(plannedEnd))) {
		return <SchedulerError startDateAfterEndDate />;
	}

	return (
		<>
			<div className="flex gap-2 items-center p-2">
				{availableRanges.length > 1 && (
					<SelectRange
						rangeMode={rangeMode}
						setRangeMode={setRangeMode}
						availableRanges={availableRanges}
					/>
				)}
				<SelectTimelineRange
					setTimelineRangeInHours={setTimelineRangeInHours}
					timelineRangeInHours={timelineRangeInHours}
				/>

				<SelectDisplayMode
					displayMode={displayMode}
					setDisplayMode={setDisplayMode}
				/>

				<SwitchButton
					checked={showSchedule}
					onCheckedChange={setShowSchedule}
				/>
				<span>
					<FormattedMessage id="SHOW_SCHEDULE" />
				</span>

				<Slider
					className="w-52 ms-auto py-2"
					onValueChange={setDayWidthMultiplier}
					value={dayWidthMultiplier}
					min={1}
					max={10}
					step={1}
				/>
			</div>
			<ErrorBoundary fallback={<SchedulerError />}>
				<div className="bg-grid-scheduler h-[80vh]">
					<Epg {...getEpgProps()}>
						<Layout
							{...getLayoutProps()}
							renderProgram={({ program, hourWidth, ...rest }) => (
								<CustomItem
									key={program.data.id}
									program={program}
									hourWidth={hourWidth}
									showStepType={false}
									showSchedule={showSchedule}
									selectedMetadataDefs={selectedMetadataDefs}
									{...rest}
								/>
							)}
							{...(CustomChannelItem
								? {
										renderChannel: ({ channel, ...rest }) => (
											<CustomChannelItem
												key={channel.uuid}
												channel={channel}
												{...rest}
											/>
										),
									}
								: {})}
							renderTimeline={
								rangeMode === "day" && timelineRangeInHours > 0
									? (props) => (
											<CustomTimeline
												timelineRangeInHours={timelineRangeInHours}
												{...props}
											/>
										)
									: undefined
							}
						/>
					</Epg>
				</div>
			</ErrorBoundary>
		</>
	);
};
