import {
	partyAvailabilityOptions,
	partyOptions,
	useParty,
	usePartyAvailability,
} from "@metronome/api/useParty";
import LoadingMetronome from "@metronome/components/LoadingMetronome";
import { toISOStringWithTimezone } from "@metronome/utils/dateHelpers";
import { createFileRoute } from "@tanstack/react-router";

const PartyPage = () => {
	const { partyId } = Route.useParams();
	const { data: party, isLoading: isPartyLoading } = useParty(partyId);
	const { data: availability, isLoading } = usePartyAvailability(partyId);
	if (isLoading || isPartyLoading) {
		return <LoadingMetronome />;
	}

  const { plannedAbsences, applicableSchedules, isAvailable } = availability;

	return (
		<div>
			<h1 className="font-bold text-xl">
				{party.firstName} {party.lastName}
			</h1>
			email: {party.email}
			<div className="mt-4">
				{plannedAbsences.length ? (
					plannedAbsences?.map((a) => (
						<div key={a.from}>{`${a.reason}: ${a.from} - ${a.to}`}</div>
					))
				) : (
					<div>No planned absence found</div>
				)}
			</div>
			<div>
				{applicableSchedules.length ? (
					applicableSchedules.map((schedule) => (
						<div key={schedule.resourceId}>{schedule.name}</div>
					))
				) : (
					<div>No applicable schedule found</div>
				)}
			</div>
			<div>{!party.userId && <span>Not bound to a user</span>}</div>
			<div>
				{isAvailable ? (
					<span>Party is available</span>
				) : (
					<span>Party is not available</span>
				)}
			</div>
		</div>
	);
};

export const Route = createFileRoute("/$workspaceId/party/$partyId")({
	component: () => <PartyPage />,
	loader: async ({ context, params }) => {
		const { workspaceId, partyId } = params;
		const { queryClient } = context;
		const today = new Date();
		let atFormatted: string;

		try {
			atFormatted = toISOStringWithTimezone(today).slice(0, -1);
		} catch (e) {
			atFormatted = undefined;
		}
		const promises = [];
		promises.push(
			queryClient.ensureQueryData(
				partyAvailabilityOptions(workspaceId, partyId, atFormatted),
			),
		);

		promises.push(
			queryClient.ensureQueryData(partyOptions(workspaceId, partyId)),
		);

		await Promise.all(promises);
	},
});