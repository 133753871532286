import { useCanvas } from "@metronome/hooks/useCanvas";
import type { ISchedule } from "@metronome/types/StepInstance";
import {
	getMinutes,
	hoursToMinutes,
	interval,
	intervalToDuration,
} from "date-fns";
import * as duration from "duration-fns";

type StepScheduleProps = {
	schedule: ISchedule;
};

const MINUTES_IN_DAY = 1440;

const getStepLowerToUpperBandDurationInMinutes = (schedule: ISchedule) => {
	const scheduleInterval = interval(
		schedule.scheduleLowerBand,
		schedule.scheduleUpperBand,
	);
	const scheduleDuration = intervalToDuration(scheduleInterval);
	const durationParsed = duration.parse(scheduleDuration);
	return duration.toMinutes(durationParsed);
};

const getStepPlannedDurationInMinutes = (schedule: ISchedule) => {
	const { plannedDuration } = schedule;

	const durationParsed = duration.parse(plannedDuration);
	return duration.toMinutes(durationParsed);
};

export const getDurationOffset = (targetStart: string) => {
	if (!targetStart) return 0;
	const noTmz = targetStart.slice(0, 19);
	const newDate = new Date(noTmz);
	const offsetMinutes = getMinutes(newDate);
	const hours = newDate.getHours();
	const offsetHoursToMinutes = hoursToMinutes(hours);
	return offsetHoursToMinutes + offsetMinutes;
};

export const StepSchedule: React.FC<StepScheduleProps> = ({ schedule }) => {
	let lowerToUpperBandDurationInMinutes: number | null = null;
	let plannedDurationInMinutes: number | null = null;
	let lowerBandOffset = 0;
	let plannedOffset = 0;

	try {
		lowerToUpperBandDurationInMinutes =
			getStepLowerToUpperBandDurationInMinutes(schedule);
		plannedDurationInMinutes = getStepPlannedDurationInMinutes(schedule);
		lowerBandOffset = getDurationOffset(schedule.scheduleLowerBand);
		plannedOffset = getDurationOffset(schedule.plannedAt);
	} catch (error) {
		console.log("error:", error);
	}

	let width = 0;
	let plannedWidth = 0;
	const height = 22;
	let x = 0;
	let plannedX = 0;
	const y = 0;

	if (lowerToUpperBandDurationInMinutes === 0) {
		width = 100;
	} else if (lowerToUpperBandDurationInMinutes) {
		width = Math.round(
			(lowerToUpperBandDurationInMinutes / MINUTES_IN_DAY) * 100,
		);
	}

	if (plannedDurationInMinutes === 0) {
		plannedWidth = 0;
	} else if (plannedDurationInMinutes) {
		plannedWidth = Math.round(
			(plannedDurationInMinutes / MINUTES_IN_DAY) * 100,
		);
	}

	if (lowerBandOffset) {
		x = Math.round((lowerBandOffset / MINUTES_IN_DAY) * 100);
	}

	if (plannedOffset) {
		plannedX = Math.round((plannedOffset / MINUTES_IN_DAY) * 100);
	}

	const draw = (ctx: CanvasRenderingContext2D, frameCount: number) => {
		ctx.fillStyle = "hsla(186 99.25% 98.44%)";
		ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

		ctx.fillStyle = "#085e68";
		ctx.fillRect(x, y, width, height);

		ctx.fillStyle = "deepskyblue";
		ctx.fillRect(plannedX, y, plannedWidth, height);
	};
	const canvasRef = useCanvas(draw);

	return (
		<canvas
			width={100}
			height={22}
			test-id="step-schedule"
			aria-label="Step duration in a day"
			ref={canvasRef}
		/>
	);
};
