import { AvatarsList } from "@metronome/components/AvatarsList";
import { StepInstanceIcon } from "@metronome/components/IconStepInstance";
import { Lozenge } from "@metronome/components/Lozenge";
import { EXPANDED_SIZE } from "@metronome/constants/planbyTheme";
import { EStepInstanceType, ISchedule } from "@metronome/types/StepInstance";
import { ETimeliness } from "@metronome/types/Timeliness";
import {
	type ProgramItem,
	useProgram,
	ProgramBox,
	ProgramContent,
	ProgramFlex,
	ProgramStack,
	ProgramTitle,
	ProgramText,
} from "@nessprim/planby-pro";
import { Link } from "@tanstack/react-router";
import { CustomItemSchedule } from "../UpdateStepInstanceSchedule";
import type { EResolution } from "@metronome/types/Resolution";
import { CheckCircledIcon, CircleIcon } from "@radix-ui/react-icons";
import { getMinutesBetweenDates } from "@metronome/utils/planby";
import type { ILightBusinessDimensionNode } from "@metronome/types/BusinessDimension";

type ExtraProps = {
	showStepType?: boolean;
	showSchedule?: boolean;
	selectedMetadataDefs?: string[];
};

function isSchedule(schedule): schedule is ISchedule {
	return Boolean(ISchedule.safeParse(schedule));
}

export const CustomItem = ({
	program,
	hourWidth,
	showSchedule,
	showStepType = true,
	selectedMetadataDefs,
	...rest
}: ProgramItem & ExtraProps) => {
	const { isLive, styles, formatTime, set12HoursTimeFormat } = useProgram({
		program,
		hourWidth,
		...rest,
	});

	const { data } = program;
	const {
		title,
		labels,
		since,
		till,
		type,
		id,
		channelUuid,
		processId,
		workspaceId,
		doneStepInstances,
		totalStepInstances,
		timeliness,
		assignments,
		resolution,
		schedule,
		businessDimension,
	} = data;

	let distanceToLowerBond: number;
	let distanceToUpperBond: number;
	if (isSchedule(schedule)) {
		// fails silently and returns 0
		const minutesToLowerBond = getMinutesBetweenDates(
			schedule.scheduleLowerBand,
			schedule.plannedAt,
		);
		// fails silently and returns 0
		const minutesToUpperBond = getMinutesBetweenDates(
			since,
			schedule.scheduleUpperBand,
		);

		const minutesWidth = hourWidth / 60;
		if (minutesToLowerBond) {
			distanceToLowerBond = minutesToLowerBond * minutesWidth;
		}
		if (minutesToUpperBond) {
			distanceToUpperBond = minutesToUpperBond * minutesWidth;
		}
	}

	const timelinessStyle =
		timeliness === ETimeliness.enum.late
			? "from-red-100 to-red-100 !border-red-500"
			: "from-background/80 to-background/80 !border-primary";

	if (type === EStepInstanceType.enum.milestone) {
		return (
			<ProgramBox
				as={"div"}
				style={styles.position}
				width={styles.width}
				className="translate-x-1/2 !bg-transparent relative !overflow-visible"
			>
				<div className="scale-150">
					<StepInstanceIcon type={type} />
				</div>
				<div className="absolute !z-50 r-0 t-0">
					<span>{title}</span>
				</div>
			</ProgramBox>
		);
	}

	return (
		<>
			{!!showSchedule && (
				<div
					className="absolute py-1"
					style={{
						transform: `translateX(-${String(distanceToLowerBond)}px)`,
						width: styles.position.left + distanceToUpperBond,
						top: styles.position.top,
						height: styles.position.height,
						left: styles.position.left,
					}}
				>
					<div className="bg-stripes border-x-2 border-primary w-full h-full p-1 flex justify-between group rounded">
						<div className="w-fit text-slate-700 opacity-0 group-hover:opacity-100 transition-opacity">
							{formatTime(
								schedule.scheduleLowerBand,
								set12HoursTimeFormat(),
							).toLowerCase()}
						</div>
						<div className="w-fit text-slate-700 opacity-0 group-hover:opacity-100 transition-opacity">
							{formatTime(
								schedule.scheduleUpperBand,
								set12HoursTimeFormat(),
							).toLowerCase()}
						</div>
					</div>
				</div>
			)}
			<ProgramBox
				width={styles.width}
				style={styles.position}
				className={`!z-[10] relative overflow-auto ${
					styles.width < 200 ? "hover:!w-[280px]" : ""
				} transition-all duration-300 ease-in-out before:h-10 !px-0 !py-0.5`}
			>
				<ProgramContent
					className={`!bg-gradient-to-r ${timelinessStyle} !border-2 !p-2`}
					width={styles.width}
					isLive={isLive}
				>
					<ProgramFlex>
						{/* {isLive && isMinWidth && <ProgramImage src={image} alt="Preview" />} */}
						<ProgramStack className="flex flex-col justify-between">
							<ProgramTitle className="flex items-center">
								{!!showStepType && (
									<span className="pe-2 text-slate-900">
										<StepInstanceIcon type={type} />
									</span>
								)}
								{(resolution as EResolution) === "done" ? (
									<CheckCircledIcon className="inline pe-1" />
								) : (
									<CircleIcon className="inline scale-90 pe-1" />
								)}
								<Link
									className="pe-2 text-slate-900"
									to="/$workspaceId/process/$processId/gates-and-steps/$stepId"
									params={{
										workspaceId,
										processId,
										stepId: id,
									}}
								>
									{title}
								</Link>
								{!!selectedMetadataDefs?.length && (
									<div className="text-slate-500">
										{(
											businessDimension as ILightBusinessDimensionNode
										).metadataValues
											.filter((v) =>
												selectedMetadataDefs.includes(v.definition.id),
											)
											.map((v) => (
												<span title={v.definition.name} key={v.id}>
													{v.value}
												</span>
											))}
									</div>
								)}
								{labels?.map((label) => (
									<Lozenge appearance="default" key={label}>
										{label}
									</Lozenge>
								))}
							</ProgramTitle>
							<div className="flex flex-row items-center">
								{styles.position.height >= EXPANDED_SIZE && (
									<ProgramText className="text-slate-900 pe-4">
										<CustomItemSchedule
											key={`${String(since)} ${String(till)}`}
											stepId={id}
											processId={processId}
											since={since}
											till={till}
											schedule={schedule}
										/>
										{totalStepInstances > 1 && (
											<span className="ms-2 px-1 bg-slate-200 rounded">{`${doneStepInstances}/${totalStepInstances}`}</span>
										)}
									</ProgramText>
								)}
								{!!assignments?.length && (
									<AvatarsList
										numberToDisplay={3}
										users={assignments}
										size={26}
									/>
								)}
							</div>
						</ProgramStack>
					</ProgramFlex>
				</ProgramContent>
			</ProgramBox>
		</>
	);
};
