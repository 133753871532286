import {
	useDeleteFormAnswer,
	useUpdateFormAnswer,
} from "@metronome/api/useForms";
import {Avatar} from "@metronome/components/Avatar";
import { Button } from "@metronome/components/Button";
import { ConfirmationModal } from "@metronome/components/ConfirmationModal";
import DateTimeCell from "@metronome/components/DateCell";
import type {
	IFormDefinition,
	IStepInstanceFormAnswer,
} from "@metronome/types/FormDefinition";
import { getInitials } from "@metronome/utils/formatText";
import {
	dataInString,
	isDateAndTime,
	isValidHttpUrl,
} from "@metronome/utils/jsonFormsUtils";
import { getUserInfoUri } from "@metronome/utils/user";
import type React from "react";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "@tanstack/react-router";
import "./FormAnswer.scss";
import { FormSubmitModal } from "./FormSubmitModal";

const MAX_FORM_ELEMENTS = 3;
const INITIAL_FORM_DATA = {};

export const FormAnswer: React.FC<{
	form: IFormDefinition;
	answer: IStepInstanceFormAnswer;
	propertyKeys: string[];
}> = ({ form, answer, propertyKeys }) => {
	const intl = useIntl();
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
	const [showFormModal, setShowFormModal] = useState<boolean>(false);
	const [formData, setFormData] = useState(INITIAL_FORM_DATA);
	const [showAllEntries, setShowAllEntries] = useState(false);

	const { stepId } = useParams({
		strict: false,
	});

	const updateFormAnswer = useUpdateFormAnswer(stepId as string, form.id);
	const deleteFormAnswer = useDeleteFormAnswer(stepId as string, form.id);

	const handleConfirmation = (): void => {
		deleteFormAnswer.mutate(answer.id);
		setShowConfirmation(false);
	};

	const closeModal = (): void => {
		setShowFormModal(false);
		setFormData({});
	};

	const handleSubmit = async (
		formUpdated: IStepInstanceFormAnswer["data"],
	): Promise<void> => {
		try {
			await updateFormAnswer.mutateAsync({ ...answer, data: formUpdated });
			setShowFormModal(false);
		} catch (_) {
			setShowFormModal(true);
		}
	};

	const handleEditAnswer = (): void => {
		setFormData(answer?.data ?? INITIAL_FORM_DATA);
		setShowFormModal(true);
	};

	const dataDisplay = (data: string): JSX.Element | string | undefined => {
		if (isValidHttpUrl(data)) {
			return <a href={data}>{data}</a>;
		}

		if (isDateAndTime(data)) {
			return <DateTimeCell datetime={data} />;
		}
		return data;
	};

	const unorderedProperties = form?.jsonSchema?.properties ?? {};

	const dataElements = propertyKeys
		.slice(0, showAllEntries ? propertyKeys.length : MAX_FORM_ELEMENTS)
		.map((key: string) => (
			<div
				className="flex flex-row gap-2"
				title={dataInString(answer?.data?.[key])}
				key={`property-answer-${key}`}
			>
				<div
					style={{ minWidth: "200px" }}
					title={unorderedProperties[key]?.title}
					className="truncate format_text_truncate font-bold"
				>
					{unorderedProperties[key]?.title} {" : "}
				</div>
				<div>{dataDisplay(dataInString(answer.data?.[key]))}</div>
			</div>
		));

	const avatarElement = answer?.createdBy ? (
		<div className="rounded-1 h-50px w-50px flex items-center justify-content-center me-3">
			<Avatar
				src={getUserInfoUri(answer?.createdBy)}
				alt={getInitials(
					answer?.createdBy?.firstName,
					answer?.createdBy?.lastName,
				)}
			/>
		</div>
	) : undefined;

	return (
		<>
			{!!showFormModal && (
				<FormSubmitModal
					show={showFormModal}
					onClose={closeModal}
					onConfirm={handleSubmit}
					title={intl.formatMessage({ id: "FORM_MODAL.TITLE" })}
					initialFormData={formData}
					form={form}
				/>
			)}
			<ConfirmationModal
				loading={deleteFormAnswer.isPending}
				show={showConfirmation}
				onClose={() => setShowConfirmation(false)}
				onConfirm={handleConfirmation}
			/>
			<div className="shadow-sm rounded p-4 mt-2">
				<div className="flex flex-row">
					<div className="flex items-center">
						{avatarElement}
						<div>
							<p className="mb-0 font-bold text-nowrap">
								{answer?.createdBy?.firstName || ""}{" "}
								{answer?.createdBy?.lastName || ""}
							</p>
							<div className="mb-0 text-nowrap">
								<DateTimeCell datetime={answer?.createdAt} />
							</div>
						</div>
					</div>
				</div>
				<div className="my-4">
					{dataElements}
					{!!(MAX_FORM_ELEMENTS < propertyKeys.length) && (
						<Button
							appearance="link"
							onClick={() => setShowAllEntries(!showAllEntries)}
						>
							{showAllEntries
								? intl.formatMessage({ id: "COLLAPSE" })
								: intl.formatMessage({ id: "EXPAND" })}
						</Button>
					)}
				</div>
				<div className="flex flex-row gap-2">
					<Button appearance="link" onClick={handleEditAnswer}>
						{intl.formatMessage({ id: "EDIT" })}
					</Button>
					<Button
						appearance="default"
						onClick={() => setShowConfirmation(true)}
					>
						{intl.formatMessage({ id: "DELETE" })}
					</Button>
				</div>
			</div>
		</>
	);
};
