import { useGetNode } from "@metronome/api/useNodes";
import HoverPopover from "@metronome/components/HoverPopover";
import LoadingMetronome from "@metronome/components/LoadingMetronome";
import { Badge } from "@metronome/components/ui/badge";
import type React from "react";

export const Node: React.FC<{ id: string; children: React.ReactNode }> = ({
	id,
	children,
}) => {
	const { data, isLoading } = useGetNode(id);
	if (isLoading) return <LoadingMetronome />;
	const { name, labels, tree } = data;
	return (
		<HoverPopover header={children}>
			<div className="flex flex-col">
				<span className="font-bold">{name}</span>
				<span className="text-slate-600">{tree.name}</span>
				{labels.map((label) => (
					<Badge key={label} variant="secondary" className="me-2">
						{label}
					</Badge>
				))}
			</div>
		</HoverPopover>
	);
};