import { Avatar } from "@metronome/components/Avatar";
import { UserPopover } from "@metronome/components/UserPopover";
import type { IParty } from "@metronome/types/Party";
import { getInitials } from "@metronome/utils/formatText";
import { getUserInfoUri, useName } from "@metronome/utils/user";
import type React from "react";
import styles from "./avatarsList.module.scss";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";

const UserComponent = ({ user }: { user: IParty }): JSX.Element => (
	<button type="button" className="flex flex-row py-2 items-center">
		<Avatar
			size={42}
			src={getUserInfoUri(user)}
			alt={getInitials(user?.firstName, user?.lastName)}
		/>
		<div className={styles.user_details} key={user.id}>
			<strong>{useName(user)}</strong>
			<span className="text-base truncate">{user.email}</span>
		</div>
	</button>
);

const DisplayAllUsers = ({
	numberOfUsersLeft,
	usersLeft,
}: {
	numberOfUsersLeft: number;
	usersLeft: IParty[];
}): JSX.Element => {
	return (
		<>
			<Popover>
				<PopoverTrigger className={styles.display_all_users}>
					{`+${numberOfUsersLeft}`}
				</PopoverTrigger>
				<PopoverContent>
					{usersLeft.map((user) => (
						<UserComponent key={user.id} user={user} />
					))}
				</PopoverContent>
			</Popover>
		</>
	);
};

type AvatarsListProps = {
	users: IParty[];
	numberToDisplay?: number;
	children?: React.ReactNode;
	size?: number;
};

export const AvatarsList: React.FC<AvatarsListProps> = ({
	users,
	numberToDisplay,
	children,
	size = 32,
}) => {
	if (!users?.length) {
		return <div />;
	}

	const uniqueUsers = [...new Map(users.map((v) => [v.id, v])).values()];

	const maxUsersToDisplay = numberToDisplay || users.length;
	const usersToDisplay = uniqueUsers.slice(0, maxUsersToDisplay);

	const usersLeft = users.slice(numberToDisplay, users.length);

	const clickableUserImages = usersToDisplay.map((user) => {
		return <UserPopover key={user.id} size={size} user={user} />;
	});

	return (
		<div className={"flex gap-2 items-center"}>
			{clickableUserImages}
			{users.length > maxUsersToDisplay && (
				<DisplayAllUsers
					usersLeft={usersLeft}
					numberOfUsersLeft={users.length - maxUsersToDisplay}
				/>
			)}
			{children}
		</div>
	);
};
