import {
	CurrentTime,
	type Timeline,
	TimelineWrapper,
	useTimeline,
	TimelineWeekMonthBox,
	TimelineWeekMonthDate,
} from "@nessprim/planby-pro";

type TimelineExtraProps = {
	timelineRangeInHours?: number; // 4 for every 4 hours
};

export function CustomTimeline(props: Timeline & TimelineExtraProps) {
	const { isWeekMonthMode, isMonthMode, time, ...rest } = useTimeline(props);
	const { timelineHeight, weekDayWidth, monthsWidth } = rest;
	const {
		formatWeekMonthDate,
		getDayMonthName,
		getTimelineProps,
		getCurrentTimeProps,
	} = rest;

	const {
		isToday,
		isCurrentTime,
		isRTL,
		isTimelineVisible,
		timelineRangeInHours = 4,
		hourWidth,
	} = props;
	const timeWithRange = time.filter(
		(t) => Number(t) % timelineRangeInHours === 0,
	);

	const { mode } = props;

	const renderWeekMonth = (item: string, index: number) => {
		const width = isMonthMode ? monthsWidth[index].width : weekDayWidth;
		const left = isMonthMode ? monthsWidth[index].left : width * index;
		const position = {
			left,
			width,
		};
		const isVisible = isTimelineVisible(position);
		if (!isVisible) return null;
		const isModernStyle = mode.style === "modern";
		return (
			<TimelineWeekMonthBox
				className="planby-timeline-box-alt"
				data-testid="timeline-item"
				key={index}
				isToday={isToday}
				isWeekMonthMode={isWeekMonthMode}
				isCurrentTime={isCurrentTime}
				timelineHeight={timelineHeight}
				styleType={mode.style}
				{...position}
			>
				<TimelineWeekMonthDate
					className="planby-timeline-week-month-date"
					styleType={mode.style}
				>
					{isModernStyle && <span>{getDayMonthName(item)}</span>}
					<span>{formatWeekMonthDate(item)}</span>
				</TimelineWeekMonthDate>
			</TimelineWeekMonthBox>
		);
	};

	const renderCustomTimeRange = (item: string, index: number) => {
		const width = hourWidth * timelineRangeInHours;
		const left = width * index;
		const position = {
			left,
			width,
		};
		const isVisible = isTimelineVisible(position);
		if (!isVisible) return null;
		return (
			<TimelineWeekMonthBox
				className="planby-timeline-box-alt"
				data-testid="timeline-item"
				key={index}
				isToday={isToday}
				isWeekMonthMode={isWeekMonthMode}
				isCurrentTime={isCurrentTime}
				timelineHeight={timelineHeight}
				styleType={mode.style}
				{...position}
			>
				<TimelineWeekMonthDate
					className="planby-timeline-week-month-date"
					styleType={mode.style}
				>
					<span>{`${item}h - ${item + timelineRangeInHours}h`}</span>
				</TimelineWeekMonthDate>
			</TimelineWeekMonthBox>
		);
	};

	return (
		<TimelineWrapper
			className="planby-timeline-wrapper"
			data-testid="timeline"
			{...getTimelineProps()}
		>
			{isCurrentTime && isToday && <CurrentTime {...getCurrentTimeProps()} />}
			{timeWithRange.map((item, index) =>
				renderCustomTimeRange(item as string, index),
			)}
		</TimelineWrapper>
	);
}
