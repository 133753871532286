import { z } from "zod";
import { NoteType } from "./NoteType";
import { IParty } from "./Party";

export const NoteSchema = z.object({
	id: z.string(),
	content: z.string(),
	createdOn: z.number(),
	noteType: NoteType,
	createdBy: IParty,
	lastEditedOn: z.number().optional(),
});

export type NoteSchema = z.infer<typeof NoteSchema>;
