import { BlockedIcon } from "@metronome/components/BlockedIcon";
import { IMetadataValue } from "@metronome/types/BusinessDimension";
import { IMetadataDefinition } from "@metronome/types/MetadataDefinition";
import { Interweave } from "interweave";
import { EmailMatcher, UrlMatcher } from "interweave-autolink";
import { FormattedMessage } from "react-intl";
import styles from "./editableMetadata.module.scss";

type DisplayMetadataValueProps = {
	metadataValue: IMetadataValue;
	definition: IMetadataDefinition;
};
export const DisplayMetadataValue: React.FC<DisplayMetadataValueProps> = ({
	metadataValue,
	definition,
}) => {
	return (
		<div key={`definition-${definition.id}`} className={styles.container}>
			<div className="flex flex-row items-start justify-between ">
				<strong className="truncate">{definition.name}</strong>
				<BlockedIcon tooltipMessage="METADATA_LOCKED" side="left" />
			</div>
			<div className="flex flex-row gap-1 flex-wrap">
				{typeof metadataValue?.value === "string" && (
					<Interweave
						key={`metadata-${metadataValue.id}`}
						content={metadataValue?.value}
						matchers={[new UrlMatcher("url"), new EmailMatcher("email")]}
					/>
				)}

				{Array.isArray(metadataValue?.value) && (
					<span>{metadataValue?.value.join(", ")}</span>
				)}

				{!metadataValue?.value && (
					<span className="italic">
						<FormattedMessage id="METADATA_NO_VALUE" />
					</span>
				)}
			</div>
		</div>
	);
};
