import { isValid } from "date-fns/isValid";
import { parseISO } from "date-fns/parseISO";
import { useState } from "react";
import { type DateRange, DayPicker } from "react-day-picker";
import Button from "./Button";
import { FormattedMessage, useIntl } from "react-intl";
import formatDate from "@metronome/utils/formatDate";

type FilterDateRangeProps = {
	value: [string, string] | undefined;
	setValue: (val: [string, string] | undefined) => void;
};
export const FilterDateRange: React.FC<FilterDateRangeProps> = ({
	value,
	setValue,
}) => {
	const columnFilterValue = value;
	const fromDate = parseISO(columnFilterValue?.[0] ?? "");
	const toDate = parseISO(columnFilterValue?.[1] ?? "");
	const isDateValid = isValid(fromDate) && isValid(toDate);
	const intl = useIntl();

	const [range, setRange] = useState<DateRange | undefined>(
		isDateValid
			? {
					from: fromDate,
					to: toDate,
				}
			: undefined,
	);
	let footer = (
		<p className="font-semibold text-primary pt-2">
			<FormattedMessage id="PICK_FIRST_DAY" />
		</p>
	);

	if (range?.from && !range?.to) {
		footer = (
			<p className="font-semibold text-primary pt-2">
				<FormattedMessage id="PICK_LAST_DAY" />
			</p>
		);
	}

	if (isDateValid && range?.from && range?.to) {
		if (!range.to) {
			footer = (
				<p className="font-semibold text-primary pt-2">
					{formatDate(range.from, "PPP", { locale: intl.locale })}
				</p>
			);
		} else if (range.to) {
			footer = (
				<p className="font-semibold text-primary pt-2">
					{formatDate(range.from, "PPP", { locale: intl.locale })}–
					{formatDate(range.to, "PPP", { locale: intl.locale })}
				</p>
			);
		}
	}

	const onSelect = (range?: DateRange): void => {
		if (!range?.from && !range?.to) {
			setValue(undefined);
			setRange(range);
		} else {
			setRange(range);
			if (range?.from && range?.to) {
				const from = range?.from.toISOString().slice(0, 10);
				const to = range.to.toISOString().slice(0, 10);
				setValue([from, to]);
			}
		}
	};
	return (
		<>
			<div className="flex flex-col items-center">
				<DayPicker
					id="test"
					mode="range"
					defaultMonth={isDateValid ? fromDate : new Date()}
					selected={range}
					footer={footer}
					onSelect={onSelect}
				/>
			</div>
			{!!columnFilterValue && (
				<div className="flex flex-row justify-end mt-2">
					<Button
						appearance="default"
						onClick={() => {
							setValue(undefined);
							setRange(undefined);
						}}
					>
						<FormattedMessage id="CLEAR_FILTERS" />
					</Button>
				</div>
			)}
		</>
	);
};
