import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import { apiGet } from "./api";
import {
	keepPreviousData,
	useInfiniteQuery,
	useQuery,
} from "@tanstack/react-query";
import type { ProcessStreamResourceAllocations } from "@metronome/types/ProcessStreamResourceAllocations";
import { useOrganizationData } from "@metronome/context/OrganizationData";
import type { ICursorPaginatedResults } from "@metronome/types/PaginatedResponse";
import type { IModelReference } from "@metronome/types/ModelReference";

const resourceAllocationsKeys = {
	definitions: (
		workspaceId: string,
		organizationId: string,
		streamId: string,
		stepTemplateId: string,
	) =>
		[
			workspaceId,
			organizationId,
			"resource-allocations",
			streamId,
			stepTemplateId,
		] as const,
};

export function useResourceAllocationsDefs(
	streamId: string,
	stepTemplateId?: string,
) {
	const workspaceId = useWorkspaceId();
	if (!workspaceId) {
		throw new Error(
			"useResourceAllocationsDefinitions: workspaceId is not defined",
		);
	}
	return useQuery({
		queryKey: resourceAllocationsKeys.definitions(
			workspaceId,
			"activeOrganization",
			streamId,
			stepTemplateId,
		),

		queryFn: () =>
			apiGet<ProcessStreamResourceAllocations[]>(
				`ws/${workspaceId}/process-streams/${streamId}/resource-allocations`,
				{
					params: {
						stepTemplateId,
					},
				},
			),
		// enabled: !!stepTemplateId,
	});
}

export function useNodesFromResourceAllocation(
		streamId: string,
		allocationId: string,
		search?: string,
	) {
		const workspaceId = useWorkspaceId();
		const { currentOrganization } = useOrganizationData();
		return useInfiniteQuery({
			queryKey: [workspaceId, currentOrganization, "nodes-from-ra", search],
			queryFn: async ({ pageParam }) =>
				apiGet<ICursorPaginatedResults<IModelReference>>(
					`ws/${workspaceId}/process-streams/${streamId}/resource-allocations/${allocationId}/nodes`,
					{
						params: { cursor: pageParam, search },
					},
				),
			initialPageParam: undefined,
			getNextPageParam: (lastPage) => lastPage?.nextCursor,
			enabled: !!allocationId,
			placeholderData: keepPreviousData,
		});
	}