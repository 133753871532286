import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
import { MasterLayout } from "../app/MasterLayout";
import type { QueryClient } from "@tanstack/react-query";
import React, { Suspense } from "react";

const TanStackRouterDevtools =
	process.env.NODE_ENV === "production"
		? () => null // Render nothing in production
		: React.lazy(() =>
				// Lazy load in development
				import("@tanstack/router-devtools").then((res) => ({
					default: res.TanStackRouterDevtools,
					// For Embedded Mode
					// default: res.TanStackRouterDevtoolsPanel
				})),
			);

export interface MyRouterContext {
	queryClient: QueryClient;
}

export const Route = createRootRouteWithContext<MyRouterContext>()({
	component: () => {
		return (
			<MasterLayout>
				<Outlet />
				<Suspense>
					<TanStackRouterDevtools />
				</Suspense>
			</MasterLayout>
		);
	},
});
