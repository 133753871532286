import { useGetPartiesFiltersInfinite } from "@metronome/api/useFilters";
import { useState } from "react";
import { ComboboxPaginated, type ComboBoxItem } from "./ComboBoxPaginated";
import { FormattedMessage, useIntl } from "react-intl";
import Button from "./Button/Button";
import LoadingMetronome from "./LoadingMetronome";

type PartyFilterComponentProps = {
	value: string[] | undefined;
	setValue: (value: string[] | undefined) => void;
};

export const PartyFilterComponent: React.FC<PartyFilterComponentProps> = ({
	value,
	setValue,
}) => {
	const intl = useIntl();
	const [search, setSearch] = useState("");
	const {
		data: partyFilters,
		fetchNextPage,
		hasNextPage,
	} = useGetPartiesFiltersInfinite(search);

	if (!partyFilters?.pages) {
		return <LoadingMetronome />;
	}

	const UNASSIGNED: ComboBoxItem = {
		value: "unassigned",
		label: intl.formatMessage({
			id: "UNASSIGNED",
		}),
		image: undefined,
	};

	const options: ComboBoxItem[] = [
		UNASSIGNED,
		...(partyFilters.pages
			.flatMap((page) => page?.results)
			.map(
				(user) =>
					({
						value: user?.id,
						label: user?.name,
						image: user?.profilePictureUri,
					}) as ComboBoxItem,
			) ?? []),
	];

	const columnFilterValue = value ?? [];

	const intialValue = Array.isArray(columnFilterValue)
		? columnFilterValue
		: [columnFilterValue];

	const selectedFilters = options?.filter((option) =>
		intialValue.includes(option.value),
	);

	return (
		<div className="mt-2 me-2">
			<ComboboxPaginated
				placeholder="CHOOSE_PARTY"
				setSearchValue={setSearch}
				value={selectedFilters.length ? selectedFilters?.[0].value : ""}
				setValue={(id) => setValue([id])}
				items={options}
				fetchNextPage={fetchNextPage}
				hasNextPage={hasNextPage}
			/>
			{selectedFilters && selectedFilters?.length > 0 && (
				<div className="flex flex-row justify-end mt-2">
					<Button
						appearance="default"
						onClick={() => {
							setValue(undefined);
						}}
					>
						<FormattedMessage id="CLEAR_FILTERS" />
					</Button>
				</div>
			)}
		</div>
	);
};
