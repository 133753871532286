import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import {
	ArrayOfRoleAndPartyAssignments,
	type Assignment,
} from "@metronome/types/PartyAssignment";
import type { AxiosResponse } from "axios";
import {
	type UseMutationResult,
	type UseQueryResult,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import { toast } from "sonner";
import { z } from "zod";
import { apiDelete, apiGet, apiGetWithSchema, apiPost } from "./api";
import type { IRoleRequirement } from "@metronome/types/RoleAssignment";

const AssignmentBaseParams = z.object({
	context: z.enum(["step-instances", "process-instances"]),
	contextId: z.string(),
});

type AssignmentBaseParams = z.infer<typeof AssignmentBaseParams>;

export function useRoles(
	context: AssignmentBaseParams["context"],
	contextId: string,
): UseQueryResult<IRoleRequirement[] | undefined, Error> {
	const workspaceId = useWorkspaceId();
	if (!workspaceId) {
		throw new Error("useRoles: workspaceId is not defined");
	}
	return useQuery({
		queryKey: [workspaceId, context, contextId, "roles"],
		queryFn: () =>
			apiGet<IRoleRequirement[]>(
				`ws/${workspaceId}/${context}/${contextId}/roles`,
			),
	});
}

export function useStepInstanceAssignments(stepInstanceId: string) {
	const workspaceId = useWorkspaceId();
	if (!workspaceId) {
		throw new Error("useStepInstanceAssignments: workspaceId is not defined");
	}

	return useQuery({
		queryKey: [workspaceId, "step-instances", stepInstanceId, "assignments"],
		queryFn: () =>
			apiGetWithSchema<ArrayOfRoleAndPartyAssignments>(
				`ws/${workspaceId}/step-instances/${stepInstanceId}/assignments`,
				ArrayOfRoleAndPartyAssignments,
			),
	});
}

export function useProcessAssignments(processId: string) {
	const workspaceId = useWorkspaceId();
	if (!workspaceId) {
		throw new Error("useProcessAssignments: workspaceId is not defined");
	}

	return useQuery({
		queryKey: [workspaceId, "process-instances", processId, "assignments"],
		queryFn: () =>
			apiGetWithSchema<ArrayOfRoleAndPartyAssignments>(
				`ws/${workspaceId}/process-instances/${processId}/assignments`,
				ArrayOfRoleAndPartyAssignments,
			),
	});
}

export function useCreateAssignments(
	context: AssignmentBaseParams["context"],
	contextId: string,
	onSuccess?: () => void,
): UseMutationResult<AxiosResponse | { data: undefined }, Error, Assignment> {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();
	if (!workspaceId) {
		throw new Error("useCreateAssignments: workspaceId is not defined");
	}
	return useMutation({
		mutationFn: ({ partyId, roleId }: Assignment) =>
			apiPost(`ws/${workspaceId}/${context}/${contextId}/assignments/`, {
				partyId,
				roleId,
				active: true,
			}),

		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [workspaceId, context, contextId, "assignments"],
			});
			onSuccess?.();
		},
		onError: (error) => {
			toast.error(`Error: ${error.message}`);
		},
	});
}

export function useDeleteAssignments(
	context: AssignmentBaseParams["context"],
	contextId: string,
	onSuccess?: () => void,
): UseMutationResult<
	AxiosResponse | { data: undefined },
	Error,
	{ assignmentId: string }
> {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();
	if (!workspaceId) {
		throw new Error("useDeleteAssignments: workspaceId is not defined");
	}
	return useMutation({
		mutationFn: ({ assignmentId }) =>
			apiDelete(
				`ws/${workspaceId}/${context}/${contextId}/assignments/${assignmentId}`,
			),

		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [workspaceId, context, contextId, "assignments"],
			});
			onSuccess?.();
		},
		onError: (error) => {
			toast.error(`Error: ${error.message}`);
		},
	});
}
