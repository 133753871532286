import {
	singleProcessOptions,
	useStages,
} from "@metronome/api/useProcessInstance";
import LoadingMetronome from "@metronome/components/LoadingMetronome";
import { StepsTimeline } from "@metronome/features/StepsTimeline";
import type { IStage } from "@metronome/types/Gate";
import type { ITimeline } from "@metronome/types/Timeline";
import { useBuildStepsEpgFromStages } from "@metronome/utils/planby";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { useMemo } from "react";

type TimelineProps = {
	gates: IStage[];
	workspaceId: string;
	processId: string;
	timeline: ITimeline;
	processName: string;
};
const Timeline: React.FC<TimelineProps> = ({
	gates,
	processId,
	timeline,
	workspaceId,
}) => {
	const { epg } = useBuildStepsEpgFromStages(gates, workspaceId, processId);
	const channels = useMemo(
		() =>
			epg.map((e) => ({
				uuid: e.id,
				logo: "",
			})),
		[epg],
	);
	return (
		<div className="p-2 h-[70vh]">
			<StepsTimeline
				key={processId}
				plannedStart={timeline.lowerTimeBand}
				plannedEnd={timeline.upperTimeBand}
				epg={epg}
				channels={channels}
			/>
		</div>
	);
};

export const Route = createFileRoute(
	"/$workspaceId/process/$processId/timeline",
)({
	loader: ({ context, params }) =>
		context?.queryClient.ensureQueryData(
			singleProcessOptions(params.workspaceId, params.processId),
		),
	component: () => {
		const { workspaceId, processId } = Route.useParams();
		const { data: processInstance } = useQuery(
			singleProcessOptions(workspaceId, processId),
		);
		const { data: gates } = useStages(processInstance.id);

		if (gates?.length) {
			return (
				<Timeline
					gates={gates}
					workspaceId={workspaceId}
					processId={processId}
					timeline={processInstance.schedule}
					processName={processInstance.name}
				/>
			);
		}
		return <LoadingMetronome />;
	},
});
