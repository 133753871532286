import {Avatar} from "@metronome/components/Avatar";
import type { IParty } from "@metronome/types/Party";
import { getInitials } from "@metronome/utils/formatText";
import { getUserInfoUri } from "@metronome/utils/user";

export const AvatarWithName = ({ user }: { user: IParty }): JSX.Element => (
	<div className="flex items-center gap-2">
		<Avatar
			src={getUserInfoUri(user)}
			alt={getInitials(user?.firstName, user?.lastName)}
		/>
		<div>
			<p className="mb-0 font-bold text-nowrap">
				{user?.firstName || ""}
				&nbsp;{user?.lastName || ""}
			</p>
		</div>
	</div>
);
