import type React from "react";
import { singleProcessStreamsOptions } from "@metronome/api/useProcessStreamInstances";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { FallbackView } from "@metronome/components/FallbackView";
import { ActiveViewProvider } from "@metronome/context/ActiveViewData";
import { useOrganizationData } from "@metronome/context/OrganizationData";
import { PageTitle } from "@metronome/context/PageData";
import useSetCurrentOrganization from "@metronome/hooks/useSetCurrentOrganization";
import { FormattedMessage, useIntl } from "react-intl";
import { ProcessStreamTemplate } from "@metronome/features/ProcessStreamTemplate";
import { UserProcessConfigLoader } from "@metronome/features/UserProcessConfigLoader";
import type { ProcessContext } from "@metronome/types/ProcessInstance";
import useUserPreferences from "@metronome/api/useUserPreference";
import LoadingMetronome from "@metronome/components/LoadingMetronome";
import type { IProcessStream } from "@metronome/types/ProcessStream";
import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "@metronome/components/ui/tabs";
import { ProcessTimelineLoader } from "./-timeline";
import * as v from "valibot";
import { BatchesMenu, RoutinesGroupedByResources } from "./-batch";

type ProcessStreamPageProps = {
	processStream: IProcessStream;
};

export const ProcessStreamPage: React.FC<ProcessStreamPageProps> = ({
	processStream,
}) => {
	const intl = useIntl();

	const { organizations } = useOrganizationData();

	const organization = organizations?.find(
		(orga) => orga.id === processStream?.organization.id,
	);

	const { selectedBatch } = Route.useSearch();

	const state = "active"; // to refactor
	const PREFERENCE_KEY = `metronome.processStream.instances.active.${processStream.id}`;

	const { data: preferences, isLoading: isUserPrefLoading } =
		useUserPreferences(PREFERENCE_KEY);

	useSetCurrentOrganization(processStream?.organization.id);

	if (!processStream) {
		return <FallbackView />;
	}

	const statesMapper = {
		active: ["active" as const],
		upcoming: ["active" as const, "ready" as const],
		past: ["closed" as const, "archived" as const],
	};

	const timesMapper = {
		active: ["past" as const, "today" as const],
		upcoming: ["future" as const],
		past: ["past" as const],
	};

	if (isUserPrefLoading) {
		return <LoadingMetronome />;
	}

	return (
		<>
			<ProcessStreamTemplate processStream={processStream} isLoading={false}>
				<PageTitle organizationName={organization?.name}>
					{intl.formatMessage({ id: "MENU.PROCESS_STREAM" })}
				</PageTitle>
			</ProcessStreamTemplate>
			<BatchesMenu streamId={processStream.id} />
			<Tabs defaultValue="list" className="mt-2">
				<TabsList className="bg-white">
					<TabsTrigger className="font-bold" value="list">
						<FormattedMessage id="LIST" />
					</TabsTrigger>
					<TabsTrigger className="font-bold" value="timeline">
						<FormattedMessage id="TIMELINE" />
					</TabsTrigger>
					<TabsTrigger className="font-bold" value="resource">
						<FormattedMessage id="RESOURCES" />
					</TabsTrigger>
				</TabsList>
				<TabsContent value="list">
					<ActiveViewProvider preferences={preferences}>
						<UserProcessConfigLoader
							context={state as ProcessContext}
							key={state}
							states={statesMapper[state as keyof typeof statesMapper]}
							times={timesMapper[state as keyof typeof timesMapper]}
							processStreamId={processStream.id}
							preferenceKey={PREFERENCE_KEY}
							preferences={preferences}
						/>
					</ActiveViewProvider>
				</TabsContent>
				<TabsContent value="timeline">
					<ProcessTimelineLoader processStreamId={processStream.id} />
				</TabsContent>
				<TabsContent value="resource">
					<RoutinesGroupedByResources
						streamId={processStream.id}
						batchId={selectedBatch}
					/>
				</TabsContent>
			</Tabs>
		</>
	);
};

const RoutineParams = v.object({
	selectedBatch: v.optional(v.string()),
	nodeReferenceId: v.optional(v.string()),
	stepTemplateId: v.optional(v.string()),
});

export const Route = createFileRoute("/$workspaceId/stream/$streamId/routine")({
	validateSearch: (searchParams) => v.parse(RoutineParams, searchParams),
	loader: async ({ context, params }) => {
		const { queryClient } = context;
		const { streamId, workspaceId } = params;

		const promises = [];

		promises.push(
			queryClient.ensureQueryData(
				singleProcessStreamsOptions(workspaceId, streamId),
			),
		);

		await Promise.all(promises);
		return;
	},
	component: () => {
		const { streamId, workspaceId } = Route.useParams();
		const { data: processStream } = useQuery(
			singleProcessStreamsOptions(workspaceId, streamId),
		);

		return <ProcessStreamPage processStream={processStream} />;
	},
});
